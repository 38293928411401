import DataService from "../services/data.service";

export const getDashboardData = () => async (dispatch) => {
    try {
        const url = `topic/feedcount/`;
        const res = await DataService.getData(url); 
        dispatch({
            type: 'GET_DASHBOARD_DATA',
            payload: res.data,
        })
    } catch (err) {
        console.log(err);
    }
}