import React from "react";
import UserContent from "./UserContent";

const User = () => {
  return (
    <div>
      <UserContent />
    </div>
  );
};

export default UserContent;
