const initialState= {
    dashboardData:{},
};

export default (state = initialState , action) => {
    switch(action.type) {
        case 'GET_DASHBOARD_DATA':
            return {...state , dashboardData: action.payload }
        default:
            return state;
    }
}
