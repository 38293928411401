import React, { useEffect } from "react";
import "./Dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../actions/dashboard";
import { Card, Spin } from "antd";
import {
  ResponsiveContainer,
  Pie,
  PieChart,
  Tooltip,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
} from "recharts";

const DashboardContent = () => {
  const dispatch = useDispatch();
  const {
    dashboardData: { results },
  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboardData());
  }, [dispatch]);

  return (
    <main className="main-container">
      <Card title="Dashboard" style={{ width: "100%" }}>
        {results ? (
          <>
            <div className="main-cards">
              <div className="card">
                <div className="card-inner">
                  <h3>Total Users</h3>
                </div>
                <h1>{results?.total_users}</h1>
              </div>
              <div className="card">
                <div className="card-inner">
                  <h3>Total Articles</h3>
                </div>
                <h1>{results?.total_topics}</h1>
              </div>
            </div>

            <div className="charts">
              <ResponsiveContainer>
                <PieChart width={730} height={250}>
                  <Pie
                    data={results?.user_specializations}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={130}
                    fill="#8884d8"
                  />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              
              <ResponsiveContainer>
                <BarChart width={730} height={250} data={results?.user_categories}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="chart-text">
              <div>
                <h5>Users on the basis of Category</h5>
              </div>
              <div>
                <h5>Articles on the basis of Category</h5>
              </div>
            </div>
          </>
        ) : (
          <div className="spinner">
            <Spin tip="Loading..." style={{ align: "center" }} />
          </div>
        )}
      </Card>
    </main>
  );
};

export default DashboardContent;
