import React from "react";
import FeedbackContent from "./FeedbackContent";

const Feedback = () => {
  return (
    <div>
      <FeedbackContent />
    </div>
  );
};

export default Feedback;
