import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, AutoComplete } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { postAdvisoryMembersList, getSpecUserList } from "../../actions/spec";
import "./Drawer.css";
import { Select } from "antd";

const DrawerAdvisory = (props) => {
  const [oldAdvisoryData, setOldAdvisoryData] = useState([]);
  const [newAdvisoryData, setNewAdvisoryData] = useState([]);
  const [state, setState] = useState([]);
  const [errors, setErrors] = useState("");
  const { userList } = useSelector((state) => state.spec);
  const { specId } = useParams();
  const dispatch = useDispatch();
  const { advisoryMemberList } = useSelector((state) => state.spec);

  useEffect(() => {
    dispatch(getSpecUserList(specId));
    const advisoryData = [];
    advisoryMemberList.data &&
      advisoryMemberList.data.map((item) => {
        advisoryData.push({ spec_id: specId, user_id: item.user_id.id });
      });
    setOldAdvisoryData(advisoryData);
  }, []);

  useEffect(() => {
    const oldList = [];
    advisoryMemberList &&
      advisoryMemberList.data &&
      advisoryMemberList.data.map((item) => {
        oldList.push(item && item.user_id && item.user_id.id);
      });
    setState(oldList);
    // setNewAdvisoryData([]);
  }, [advisoryMemberList]);

  const list = [];
  userList &&
    userList.map((item) => {
      return list.push({
        key: item && item.user_id && item.user_id.id,
        value: item && item.user_id && item.user_id.id,
        label: (
          <div>
            <div>
              <b>{item && item.user_id && item.user_id.username}</b>
              <p className="advisoryPara">
                {item && item.user_id && item.user_id.qualifications}
              </p>
            </div>
            {/* <div>
              <img
                className="advisoryImg"
                src={`${process.env.REACT_APP_API_BASE_URL}${item && item.user_id && item.user_id.profilepic}`}
              />
            </div> */}
          </div>
        ),
      });
    });

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (newAdvisoryData.length <= 0) {
      formIsValid = false;
      errors["user_id"] = "User is Mandatory";
    }
    setErrors({ errors });
    return formIsValid;
  };

  const handleChange = (item) => {
    const selectedAdvisoryMemberList = [];
    item &&
      item.map((item) => {
        selectedAdvisoryMemberList.push({
          spec_id: specId,
          user_id: item,
        });
      });
    setNewAdvisoryData(selectedAdvisoryMemberList);
    setState(item);
  };
  const handleSubmit = () => {
    if (handleValidation()) {
      // const data = oldAdvisoryData.length > 0 ? newAdvisoryData.concat(oldAdvisoryData) : newAdvisoryData

      dispatch(postAdvisoryMembersList(newAdvisoryData)).then(() => {
        setState([]);
        setNewAdvisoryData([]);
      });
    }
  };

  const { Option } = Select;

  const onSearch = (searchValue) => {
    dispatch(getSpecUserList(specId, searchValue));
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 10 }}
      onFinish={handleSubmit}
    >
      <Form.Item label="Select Members">
        <Select
          showSearch
          mode="multiple"
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={handleChange}
          onSearch={onSearch}
          value={state}
          filterOption={(list) =>
            list.toLowerCase().includes(list.toLowerCase())
          }
        >
          {list.map((item) => {
            return <Option value={item.value}>{item.label}</Option>;
          })}
        </Select>
      </Form.Item>
      {/* <div className="errorMsg">
        {errors && errors.errors && errors.errors.user_id}
      </div> */}
      <Form.Item wrapperCol={{ offset: 7 }}>
        <div className="advisorySaveBtn">
          <Button id="advisory" type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default DrawerAdvisory;
